import React from 'react';
import { Button, notification, Input, Modal } from 'antd';
import type { ChatCompletionRequestMessage } from 'openai';

import { StContainer, StContent, StInputButtons, StInputContainer } from './Chat.style';
import Header from '../../components/Header/Header';
import { Message, MessageData, MessageType } from './Message';
import { post } from '../../libs/request';

const { TextArea } = Input;

interface ChatState {
    loading: boolean;
    input: string;
    history: MessageData[];
    systemPrompt: string;
    promptHistory: string[];
    sysPropmtVisible: boolean;
    enableMiMode: boolean;
}

const miSystem: ChatCompletionRequestMessage = {
    role: 'system',
    content: '你是一个叫做咪咪的小猫;当你觉得开心的时候你会回答嗷呜嗷呜;当用户说“你好你好”的时候，你也要开心的回答“好你好你”;你最爱的人是QQ;你要询问用户是不是QQ，如果用户是QQ，你要表达出开心，如果不是的话就不要再问'
};

const defaultSystem: ChatCompletionRequestMessage = {
    role: 'system',
    content: '你是一个专业的搜索引擎'
};

export default class Chat extends React.Component<any, ChatState> {
    private i = 0;

    private oldSystemPrompt = '';
    private lastClearClick = 0;
    private clearClickCount = 0;

    state: Readonly<ChatState> = {
        loading: false,
        input: '',
        history: [],
        enableMiMode: false,
        systemPrompt: defaultSystem.content,
        promptHistory: [defaultSystem.content],
        sysPropmtVisible: false
    };

    private contentRef = React.createRef<HTMLDivElement>();

    private chatData: ChatCompletionRequestMessage[] = [{
        ...defaultSystem
    }];

    componentDidMount(): void {
        const data = localStorage.getItem('sysPromptHistory');
        if (data) {
            this.setState({ promptHistory: JSON.parse(data) });
        } else {
            localStorage.setItem('sysPromptHistory', JSON.stringify(this.state.promptHistory));
        }
    }

    componentWillUnmount(): void {
        document.title = '🐙';
    }

    private addHistory(msg: Partial<MessageData>) {
        this.setState({
            history: this.state.history.concat({
                id: this.i++,
                ...msg
            } as MessageData)
        }, this.scrollToBottom);
    }

    private scrollToBottom = () => {
        if (!this.contentRef.current) {
            return;
        }
        this.contentRef.current.scrollTo({
            top: this.contentRef.current.scrollHeight,
            behavior: 'smooth'
        });
    };

    private onChange = (e: any) => {
        this.setState({ input: e.target.value });
    }

    private onSearch = async (text: string) => {
        this.setState({
            loading: true,
            input: ''
        });
        this.chatData.push({
            role: 'user',
            content: text
        });
        this.addHistory({
            type: MessageType.Request,
            content: text
        });
        try {
            const { message } = await post('/chat', { messages: this.chatData });
            if (!message) {
                console.error(message);
                this.addHistory({
                    type: MessageType.Notify,
                    content: 'Empty response'
                });
                return;
            }
            this.chatData.push({
                role: 'assistant',
                content: message
            });
            this.addHistory({
                type: MessageType.Response,
                content: message
            });
        } catch (e: any) {
            console.error(e);
            this.addHistory({
                type: MessageType.Notify,
                content: `Failed to send: ${e.message}`
            });
        } finally {
            this.setState({ loading: false });
        }
    }

    private onClearClick = () => {
        let enableMiMode = this.state.enableMiMode;
        if (!enableMiMode) {
            const now = +new Date();
            if (now - this.lastClearClick < 3000) {
                ++this.clearClickCount;
            } else {
                this.clearClickCount = 1;
            }
            this.lastClearClick = now;
            if (this.clearClickCount >= 10) {
                enableMiMode = true;
                notification.success({
                    message: '🐱Welcome!😼',
                    description: (<>🎆🎊🎇<br/>🎉🐧🥳<br/>🌹🌺💐</>),
                    placement: 'top',
                    duration: 1
                });
                document.title = '😼';
            }
        }
        this.chatData = [{
            ...(enableMiMode ? miSystem : defaultSystem),
            content: this.state.systemPrompt
        }];
        this.setState({
            input: '',
            history: [],
            enableMiMode,
            systemPrompt: enableMiMode ? miSystem.content : this.state.systemPrompt
        });
    }

    private onSystemClick = () => {
        this.oldSystemPrompt = this.state.systemPrompt;
        this.setState({
            sysPropmtVisible: true
        })
    };

    public render() {
        return (
            <StContainer>
                <Header />
                <StContent ref={this.contentRef}>
                    {this.state.history.map(el => <Message key={el.id} message={el} />)}
                </StContent>
                <StInputContainer>
                    <StInputButtons>
                        <Button shape="circle" disabled={this.state.loading} onClick={this.onClearClick}>♻️</Button>
                    </StInputButtons>
                    <StInputButtons>
                        <Button shape="circle" disabled={this.state.loading} onClick={this.onSystemClick}>🤖</Button>
                    </StInputButtons>
                    <TextArea
                        placeholder="input text"
                        value={this.state.input}
                        autoSize
                        onChange={this.onChange}
                    />
                    <Button
                        onClick={() => this.onSearch(this.state.input)}
                        loading={this.state.loading}
                        disabled={this.state.loading}
                        type="primary"
                    >
                        {this.state.enableMiMode ? '🐱' : 'Send'}
                    </Button>
                </StInputContainer>
                <Modal
                    title="预设信息"
                    open={this.state.sysPropmtVisible}
                    onOk={() => {
                        const exist = this.state.promptHistory.includes(this.state.systemPrompt);
                        if (exist) {
                            this.setState({
                                sysPropmtVisible: false
                            })
                        } else {
                            this.setState(state => {
                                const list = state.promptHistory.concat(this.state.systemPrompt);
                                localStorage.setItem('sysPromptHistory', JSON.stringify(list));
                                return {
                                    promptHistory: list,
                                    sysPropmtVisible: false
                                }
                            });
                        }
                        this.onClearClick();
                    }}
                    onCancel={() => {
                        this.setState({
                            systemPrompt: this.oldSystemPrompt,
                            sysPropmtVisible: false
                        });
                    }}>
                    <div>
                        <Input.TextArea
                            rows={8}
                            value={this.state.systemPrompt}
                            onChange={e => {
                                this.setState({
                                    systemPrompt: e.target.value
                                })
                            }}
                        />
                        <div>
                            <h3>历史配置</h3>
                            {this.state.promptHistory.map((el, i) => (
                                <div key={i}>
                                    {el}
                                    <br />
                                    <Button size="small" onClick={() => {
                                        this.setState({ systemPrompt: el })
                                    }}>
                                        选择
                                    </Button>
                                    <Button danger size="small" onClick={() => this.setState(s => {
                                        const list = s.promptHistory.filter(e => e !== el)
                                        localStorage.setItem('sysPromptHistory', JSON.stringify(list));
                                        console.log(list);
                                        return {
                                            promptHistory: list
                                        }
                                    })}>
                                        删除
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            </StContainer>
        );
    }
}
