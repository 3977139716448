import styled from "styled-components";

export const StHoverButtons = styled.div<{ isResponse?: boolean }>`
    margin-top: 5px;
    padding: 2px;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    align-self: ${props => props.isResponse ? 'flex-start' : 'flex-end'}
`;

export const StButton = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        background: #CCC;
    }

    & + & {
        margin-inline-start: 3px;
    }
`;

export const StMessage = styled.div<{ isResponse?: boolean }>`
    position: relative;
    max-width: 80%;
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
    padding: 0 10px;
    align-self: ${props => props.isResponse ? 'flex-start' : 'flex-end'};
    background: ${props => props.isResponse ? '#f8f8f8' : '#eaf5ea'};
    display: flex;
    flex-direction: column;
    
    & img {
        background: lightgray;
        borer-radius: 2px;
        min-height: 50px;
        min-width: 100px;
        object-fit: contain;
    }

    & pre {
        border: 1px solid #AAA;
        padding: 5px;
        border-radius: 5px;
    }

    & pre code {
        white-space: pre-wrap;
    }
`;

export const StNotify = styled.div`
    text-align: center;
    opacity: 0.5;
    font-size: 12px;
`;
