import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { StMessage, StNotify, StHoverButtons, StButton } from "./Message.style";
import { notification } from 'antd';

export const enum MessageType {
    Unknown,
    Notify,
    Request,
    Response
}

export interface MessageData {
    type: MessageType;
    content: string;
    id: number;
}

export const Message = (props: { message: MessageData }) => {
    const { message } = props;
    const [showOrigin, setShowOrigin] = useState(false);
    if (message.type === MessageType.Notify) {
        return (<StNotify>{message.content}</StNotify>);
    }
    const isResponse = message.type === MessageType.Response;
    return (
        <StMessage isResponse={isResponse}>
            {
                showOrigin ? (
                    <pre>{message.content}</pre>
                ) : (
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                )
            }
            <StHoverButtons isResponse={isResponse}>
                <StButton onClick={() => copyText(message.content)}>📋</StButton>
                <StButton onClick={() => setShowOrigin(s => !s)}>🔁</StButton>
            </StHoverButtons>
        </StMessage>
    );
}

function copyText(text: string) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        notification.success({
            message: 'Copied!'
        });
    } else {
        notification.error({
            message: 'Failed to copy!'
        });
    }
}